.custom-css {
    border-radius: 5%;
}

.project-card-container {
    transition: transform 0.3s ease;
}

.project-card-container:hover {
    transform: scale(1.05);
}

.service-img {
    width: 200px !important;
    height: 200px !important;
}